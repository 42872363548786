import { AppTheme } from "@hex/common";
import React from "react";

import { HexMenuDivider, HexMenuItem } from "../../hex-components";
import { LocalStorageKeys, useLocalStorage } from "../../hooks/useLocalStorage";
import { usePrefersColorScheme } from "../../hooks/usePrefersColorScheme";
import { THEME_DISPLAY_NAMES } from "../../theme/common/theme";
import { PreferencesIcon } from "../icons/CustomIcons";

interface QuickSettingsProps {
  flatten?: boolean;
}

export const QuickSettings: React.FunctionComponent<QuickSettingsProps> = ({
  flatten = false,
}) => {
  const preferredColorScheme = usePrefersColorScheme();
  const [themeName, toggleTheme] = useLocalStorage(LocalStorageKeys.THEME_NAME);

  const contents = (
    <>
      <HexMenuDivider title="Theme" />
      <HexMenuItem
        icon={themeName === AppTheme.SYS_PREF ? "selection" : "circle"}
        shouldDismissPopover={false}
        text={`System (${THEME_DISPLAY_NAMES[preferredColorScheme]})`}
        onClick={() => toggleTheme(AppTheme.SYS_PREF)}
      />
      <HexMenuItem
        icon={themeName === AppTheme.LIGHT ? "selection" : "circle"}
        shouldDismissPopover={false}
        text="Light"
        onClick={() => toggleTheme(AppTheme.LIGHT)}
      />
      <HexMenuItem
        icon={themeName === AppTheme.DARK ? "selection" : "circle"}
        shouldDismissPopover={false}
        text="Dark"
        onClick={() => toggleTheme(AppTheme.DARK)}
      />
    </>
  );

  if (flatten) {
    return contents;
  }

  return (
    <HexMenuItem icon={<PreferencesIcon />} text="Preferences">
      {contents}
    </HexMenuItem>
  );
};
