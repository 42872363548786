import { gql } from "@apollo/client";
import { Classes } from "@blueprintjs/core";
import {
  HexOrder,
  HexType,
  SpecialVersionType,
  convertRichTextToPlainText,
} from "@hex/common";
import React, { ReactNode, useMemo } from "react";
import styled, { useTheme } from "styled-components";

import {
  HexMenu,
  HexMenuDivider,
  HexMenuItem,
  HexNonIdealState,
  HexPopover,
  HexSpinner,
} from "../../hex-components";
import { useCurrentUser } from "../../hooks/me/useCurrentUser.js";
import { ORG_ID } from "../../orgs";
import { Routes } from "../../route/routes";
import { useProjectContext } from "../../util/projectContext";
import { WorkspaceSearchButton } from "../common/WorkspaceSearchButton";
import { HexListFragment } from "../hex-list/HexList.generated";
import {
  ComponentIcon,
  FilledStarIcon,
  ProjectIcon,
} from "../icons/CustomIcons";

import { NavUserMenuNew } from "./NavUserMenuNew.js";
import { useGetHexesForQuickNavQuery } from "./QuickNavPopover.generated";

gql`
  query GetHexesForQuickNav(
    $onlyStarred: Boolean
    $onlyViewed: Boolean
    $order: HexOrder
    $first: Int
  ) {
    hexes(
      ownershipLevel: ALL
      onlyPublished: false
      onlyStarred: $onlyStarred
      onlyViewed: $onlyViewed
      order: $order
      trashed: false
      first: $first
      hexTypes: [PROJECT, COMPONENT]
    ) {
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          ...HexListFragment
        }
      }
    }
  }
`;

const StyledHexPopover = styled(HexPopover)`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const PopoverWrapper = styled.div`
  min-width: 275px;
  max-width: 275px;
  max-height: 60vh;
  padding: 5px;
  overflow-y: auto;

  .${Classes.MENU_HEADER} {
    border: none;
  }
`;

const SearchButton = styled.div`
  padding: 10px 10px 5px;
`;

interface QuickNavPopoverProps {
  children: ReactNode;
  hideSearch?: boolean;
}

export const QuickNavPopover: React.ComponentType<QuickNavPopoverProps> =
  React.memo(function QuickNavPopover({ children, hideSearch = false }) {
    const {
      data: starredHexesData,
      error: starredError,
      loading: starredLoading,
    } = useGetHexesForQuickNavQuery({
      variables: {
        order: null,
        onlyStarred: true,
        onlyViewed: false,
        first: 10,
      },
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-first",
    });

    const currentUser = useCurrentUser();
    const projectContext = useProjectContext({ safe: true });
    const currentHexId = projectContext?.hexId;

    const {
      data: recentHexesData,
      error: recentError,
      loading: recentLoading,
    } = useGetHexesForQuickNavQuery({
      variables: {
        order: HexOrder.RECENTLY_VIEWED,
        onlyStarred: false,
        onlyViewed: true,
        first: 20,
      },
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-first",
    });

    const starredHexes = useMemo(
      () =>
        starredHexesData?.hexes?.edges
          .filter((node) => node.node.id !== currentHexId)
          .map(({ node }) => node)
          .slice(0, 10) ?? [],
      [currentHexId, starredHexesData?.hexes?.edges],
    );

    const recentHexes = useMemo(
      () =>
        recentHexesData?.hexes?.edges
          .filter(
            (node) =>
              node.node.id !== currentHexId && !node.node.starredByViewer,
          )
          .map(({ node }) => node)
          .slice(0, 5) ?? [],
      [currentHexId, recentHexesData?.hexes?.edges],
    );

    const quickNavContents = useMemo(() => {
      return (
        <PopoverWrapper>
          {currentUser && <NavUserMenuNew currentUser={currentUser} />}
          {!hideSearch && (
            <SearchButton>
              <WorkspaceSearchButton location="quick-nav" />
            </SearchButton>
          )}
          <HexMenu>
            {starredLoading || recentLoading ? (
              <HexSpinner description="Loading..." />
            ) : (
              <>
                {!starredError && starredHexes.length > 0 && (
                  <>
                    <HexMenuDivider title="Favorites" />
                    {starredHexes.map((item, i) => (
                      <QuickNavItem key={i} data={item} />
                    ))}
                  </>
                )}
                {!recentError && (
                  <>
                    <HexMenuDivider title="Last viewed by you" />
                    {recentHexes.length > 0 ? (
                      recentHexes.map((item, i) => (
                        <QuickNavItem key={i} data={item} />
                      ))
                    ) : (
                      <HexNonIdealState
                        $minimal={true}
                        $small={true}
                        css={`
                          margin: 0 5px;
                          width: auto;
                          flex: 1 1 auto;
                        `}
                        title="Recently viewed items will appear here"
                      />
                    )}
                  </>
                )}
              </>
            )}
          </HexMenu>
        </PopoverWrapper>
      );
    }, [
      currentUser,
      hideSearch,
      recentError,
      recentHexes,
      recentLoading,
      starredError,
      starredHexes,
      starredLoading,
    ]);

    return (
      <StyledHexPopover
        content={quickNavContents}
        hoverCloseDelay={150}
        interactionKind="hover"
        minimal={true}
        placement="bottom-start"
      >
        {children}
      </StyledHexPopover>
    );
  });

interface QuickNavItemProps {
  data: HexListFragment;
}

const QuickNavItem: React.ComponentType<QuickNavItemProps> = React.memo(
  function QuickNavItem({ data }) {
    const args = data.effectiveRole
      ? data.canViewLogic
        ? {
            hexId: data.id,
            version: SpecialVersionType.DRAFT,
          }
        : {
            hexId: data.id,
            version: SpecialVersionType.LAST_PUBLISHED,
          }
      : {
          hexId: data.id,
          version: SpecialVersionType.DRAFT,
        };

    const { userActionColor } = useTheme();

    const route =
      data.hexType === HexType.COMPONENT
        ? Routes.COMPONENT
        : data.canViewLogic
          ? Routes.LOGIC
          : Routes.APP;

    const hexTitle = data.title;
    const description =
      data.description != null
        ? convertRichTextToPlainText(data.description)
        : data.description;

    return (
      <HexMenuItem
        key="editMenuItem"
        href={Routes.href(ORG_ID, true, route, args)}
        icon={
          data.starredByViewer ? (
            <FilledStarIcon color={userActionColor} />
          ) : data.hexType === HexType.COMPONENT ? (
            <ComponentIcon />
          ) : (
            <ProjectIcon />
          )
        }
        text={hexTitle}
        title={description ?? undefined}
      />
    );
  },
);
