import { OrgRole } from "@hex/common";
import { MARKETING_SITE_BASE_URL } from "@hex/common/src/DocsLink.js";
import { rgba } from "polished";
import React, { useMemo } from "react";
import styled from "styled-components";

import { useCurrentUser } from "../../hooks/me/useCurrentUser";
import { useOnClickProps } from "../../hooks/useOnClickProps";
import { Routes } from "../../route/routes";
import LogoSVG from "../../static/icon/hex-logo.inline.svg";
import { CyData } from "../../util/cypress";
import { useFeatureGates } from "../feature-gate/FeatureGateContext";

import { QuickNavPopover } from "./QuickNavPopover";

const LogoNavbarGroup = styled.a<{ $linkEnabled: boolean }>`
  ${({ theme }) => theme.resets.a}

  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 15px;

  && {
    cursor: ${({ $linkEnabled }) => ($linkEnabled ? "pointer" : "default")};
  }

  transition: background-color ${({ theme }) => theme.animation.duration}
    ${({ theme }) => theme.animation.easing};

  &:hover {
    background-color: ${({ $linkEnabled, theme }) =>
      $linkEnabled && rgba(theme.highlightColor, 0.04)};
  }

  &:active {
    background-color: ${({ $linkEnabled, theme }) =>
      $linkEnabled && rgba(theme.highlightColor, 0.12)};
  }
`;

const StyledLogo = styled.div<{
  $forceDarkTheme?: boolean;
  $linkEnabled: boolean;
}>`
  flex: none;

  overflow: hidden;

  cursor: ${({ $linkEnabled }) => ($linkEnabled ? "pointer" : "default")};

  svg {
    width: auto;
    height: 14px;

    path {
      fill: ${({ $forceDarkTheme, theme }) =>
        $forceDarkTheme ? theme.marketingColors.ROSE_QUARTZ : theme.logoColor};
    }
  }

  @media (max-width: ${({ theme }) => `${theme.mediaQuery.MOBILE}px`}) {
    svg {
      width: auto;
      height: 14px;
    }
  }
`;

const LogoContainer = styled.div`
  display: flex;
  flex: none;
  min-width: 50px;
  max-width: 160px;
  height: 100%;
`;

const PrefixedText = styled.p`
  margin: 0 6px 0 0;

  color: ${({ theme }) => theme.fontColor.MUTED};
`;

const StyledCustomLogoContainer = styled.a<{ $linkEnabled: boolean }>`
  cursor: ${({ $linkEnabled }) => ($linkEnabled ? "pointer" : "default")};
`;

const StyledCustomLogo = styled.img<{ $linkEnabled: boolean }>`
  max-height: 50px;
  padding: 8px 15px;

  cursor: ${({ $linkEnabled }) => ($linkEnabled ? "pointer" : "default")};
`;

interface LogoClickProps {
  target?: string | undefined;
  href?: string;
  onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

interface QuickNavProps {
  enabled?: boolean;
  hideSearch?: boolean;
}

interface HexLogoProps {
  forceDarkTheme?: boolean;
  customAppIconUrl?: string;
  appIconLoading?: boolean;
  prefixedText?: string;
  className?: string;
  linkEnabled?: boolean;
  quickNav?: QuickNavProps;
  newTab?: boolean;
}

export const HexLogo: React.ComponentType<HexLogoProps> = React.memo(
  function HexLogo({
    appIconLoading = false,
    className,
    customAppIconUrl,
    forceDarkTheme = false,
    linkEnabled = true,
    newTab = false,
    prefixedText,
    quickNav = {
      enabled: false,
      hideSearch: false,
    },
  }) {
    const customAppIcon =
      useFeatureGates({ safe: true })?.customAppIcon ?? false;
    const currentUser = useCurrentUser();
    const isAnonymousOrNoUser =
      !currentUser?.orgRole || currentUser?.orgRole === OrgRole.ANONYMOUS;

    const normalClickProps = useOnClickProps({
      to: Routes.HOME.getUrl({}),
      target: newTab ? "_blank" : undefined,
    });

    // we are not using `useOnClickProps` here because the marketing site is an external URL.
    const anonymousClickProps = {
      href: MARKETING_SITE_BASE_URL,
      target: newTab ? "_blank" : undefined,
    };

    let logoProps: LogoClickProps | undefined;
    let customLogoProps: LogoClickProps | undefined;

    if (linkEnabled) {
      logoProps = isAnonymousOrNoUser ? anonymousClickProps : normalClickProps;

      customLogoProps = isAnonymousOrNoUser ? undefined : normalClickProps;
    }

    const logoContents = useMemo(() => {
      if (appIconLoading && customAppIcon) {
        return null;
      } else if (customAppIconUrl && customAppIcon) {
        return (
          <StyledCustomLogoContainer
            $linkEnabled={!!customLogoProps}
            {...customLogoProps}
          >
            <StyledCustomLogo
              $linkEnabled={!!customLogoProps}
              src={customAppIconUrl}
            />
          </StyledCustomLogoContainer>
        );
      } else {
        return (
          <LogoNavbarGroup
            $linkEnabled={linkEnabled}
            data-cy={CyData.HEX_LOGO}
            {...logoProps}
          >
            <StyledLogo
              $forceDarkTheme={forceDarkTheme}
              $linkEnabled={linkEnabled}
            >
              {prefixedText && <PrefixedText>{prefixedText}</PrefixedText>}
              <LogoSVG />
            </StyledLogo>
          </LogoNavbarGroup>
        );
      }
    }, [
      appIconLoading,
      customAppIcon,
      customAppIconUrl,
      customLogoProps,
      linkEnabled,
      logoProps,
      forceDarkTheme,
      prefixedText,
    ]);

    return (
      <LogoContainer className={className}>
        {isAnonymousOrNoUser || !quickNav.enabled ? (
          logoContents
        ) : (
          <QuickNavPopover hideSearch={quickNav.hideSearch}>
            {logoContents}
          </QuickNavPopover>
        )}
      </LogoContainer>
    );
  },
);
