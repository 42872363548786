import { Classes, Navbar, NavbarGroup } from "@blueprintjs/core";
import { OrgRole, orgRoleHasEditPermissions } from "@hex/common";
import React, { ReactNode } from "react";
import { useRouteMatch } from "react-router";
import styled, { css } from "styled-components";

import { HexButton } from "../../hex-components";
import { useCurrentUser } from "../../hooks/me/useCurrentUser";
import { useOnClickProps } from "../../hooks/useOnClickProps";
import { MagicElement } from "../../magic/MagicElement.js";
import { Routes } from "../../route/routes";
import { SingleChevronLeftIcon } from "../icons/CustomIcons";
import { DisabledOverlay } from "../sidebar/create-component/DisabledOverlay";
import { useComponentSidebar } from "../sidebar/create-component/useComponentSidebar";
import { ImportNewProjectDialog } from "../upload/ImportProjectDialog";
import { NewComponentDialog } from "../upload/NewComponentDialog";
import { NewProjectDialog } from "../upload/NewProjectDialog";

import { HexLogo } from "./HexLogo";

export const HOMEPAGE_V2_NAVIGATION_BAR_HEIGHT = 60;
const NAVIGATION_BAR_HEIGHT = 40;

const StyledNavbar = styled(Navbar)<{
  $isInHome?: boolean;
  $isInApp?: boolean;
  $isComponent?: boolean;
  $disabled?: boolean;
  $isInDataBrowser: boolean;
}>`
  ${({ $disabled }) => $disabled && `opacity: 20%;`}

  transition: opacity ${({ theme }) => theme.animation.duration} ${({
    theme,
  }) => theme.animation.easing};

  && {
    display: flex;
    flex: none;
    justify-content: space-between;
    min-width: ${({ $isInDataBrowser, $isInHome }) =>
      !$isInDataBrowser && !$isInHome && "800px"};
    padding: 0;

    height: ${({ $isInHome }) =>
      $isInHome
        ? `${HOMEPAGE_V2_NAVIGATION_BAR_HEIGHT}px`
        : `${NAVIGATION_BAR_HEIGHT}px`};

    background-color: transparent;
    box-shadow: ${({ $isInHome, theme }) =>
      $isInHome ? "none" : theme.boxShadow.NAV_BAR};

    ${({ $isInApp }) =>
      !$isInApp
        ? css`
            position: fixed;
            top: 0;
            left: 0;
            right: 0;

            width: 100%;

            background-color: transparent;
            box-shadow: none;
          `
        : ``}
  }
`;

const BackButtonWrapper = styled.div<{ $isInDataBrowser: boolean }>`
  display: flex;
  padding-left: ${({ $isInDataBrowser }) =>
    $isInDataBrowser ? "4px" : "22px"};
`;

const BackButton = styled(HexButton)`
  .${Classes.BUTTON_TEXT} {
    font-weight: ${({ theme }) => theme.fontWeight.MEDIUM};
    font-size: ${({ theme }) => theme.fontSize.LARGE};
  }
`;

const LogoSearchGroup = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`;

const RightAlignedNavbarGroup = styled(NavbarGroup)<{
  $isInHome?: boolean;
}>`
  display: flex;
  gap: 10px;
  align-items: stretch;
  height: 100%;
  justify-content: ${({ $isInHome }) => ($isInHome ? "flex-end" : undefined)};
  flex: ${({ $isInHome }) => ($isInHome ? 1 : undefined)};
`;

interface NavigationBarProps {
  isInApp?: boolean;
  isInSettings?: boolean;
  isInDataBrowser?: boolean;
  isInHome?: boolean;
  isPlatformAdminView?: boolean;
  inOnboarding?: boolean;
  children?: ReactNode;
  leftOfLogoChildren?: ReactNode;
  rightAlignedChildren?: ReactNode;
  leftAlignedChildren?: ReactNode;
  linkEnabled?: boolean;
  isInMagic?: boolean;
}

export const NavigationBar: React.FunctionComponent<NavigationBarProps> = ({
  children,
  inOnboarding = false,
  isInApp = true,
  isInDataBrowser = false,
  isInHome = false,
  isInMagic = false,
  isInSettings = false,
  isPlatformAdminView = false,
  leftAlignedChildren,
  leftOfLogoChildren,
  linkEnabled,
  rightAlignedChildren,
}) => {
  const currentUser = useCurrentUser();
  const isInLogicView = useRouteMatch(Routes.LOGIC.path) != null;
  const isInComponentView = useRouteMatch(Routes.COMPONENT.path) != null;

  const orgRole = currentUser?.orgRole;
  const uploadOrCreateDisabled =
    !orgRole ||
    orgRole === OrgRole.GUEST ||
    !orgRoleHasEditPermissions(orgRole);

  const { closeComponentSidebar, createComponentSidebarOpen } =
    useComponentSidebar();

  const disableNavbar =
    (isInComponentView || isInLogicView) && createComponentSidebarOpen;

  // if the user is from platform admin, redirect back to the platform admin page.
  const backButtonClickProps = useOnClickProps({
    to: isPlatformAdminView
      ? Routes.ADMIN_PLATFORM.getUrl({})
      : Routes.HOME.getUrl({}),
  });

  return (
    <StyledNavbar
      $disabled={disableNavbar}
      $isInApp={isInApp}
      $isInDataBrowser={isInDataBrowser}
      $isInHome={isInHome}
    >
      <LogoSearchGroup>
        {isInSettings || isPlatformAdminView || isInDataBrowser ? (
          <BackButtonWrapper $isInDataBrowser={isInDataBrowser}>
            <BackButton
              icon={<SingleChevronLeftIcon />}
              intent="none"
              minimal={true}
              text={
                isInDataBrowser
                  ? "Data manager"
                  : isPlatformAdminView
                    ? "Platform admin"
                    : "Settings"
              }
              {...backButtonClickProps}
            />
          </BackButtonWrapper>
        ) : isInMagic ? (
          <BackButton
            icon={<MagicElement />}
            intent="none"
            minimal={true}
            {...backButtonClickProps}
          />
        ) : (
          <>
            {leftOfLogoChildren}
            <HexLogo
              forceDarkTheme={!isInApp && !inOnboarding}
              linkEnabled={linkEnabled}
              quickNav={{
                enabled: isInApp,
                hideSearch: isInHome,
              }}
            />
          </>
        )}
        {leftAlignedChildren}
      </LogoSearchGroup>
      {children}
      {isInApp && (
        <RightAlignedNavbarGroup $isInHome={isInHome}>
          {rightAlignedChildren}
        </RightAlignedNavbarGroup>
      )}
      {!uploadOrCreateDisabled && (
        <>
          <NewProjectDialog />
          <NewComponentDialog />
          <ImportNewProjectDialog />
        </>
      )}
      {disableNavbar && <DisabledOverlay onClick={closeComponentSidebar} />}
    </StyledNavbar>
  );
};
